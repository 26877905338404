<template>
  <v-sheet class="pa-4" elevation="2" v-if="!isLoading">
    <v-row align="center">
      <v-col cols="auto">
        <ContractStatus :contract-status="status" />
      </v-col>

      <v-col cols="auto">
        <BaseText>
          {{ $t("SEMINAR_SPEAKER.CONTRACT_DETAILS.CONTRACT_ID", { id: contract.id }) }}
        </BaseText>
      </v-col>

      <v-col cols="auto">
        <BaseText>
          {{ $t("SEMINAR_SPEAKER.CONTRACT_DETAILS.ROLE", { roleName: role.display_name }) }}
        </BaseText>
      </v-col>

      <v-spacer />

      <ContractActionIndicator v-if="contractIsNeedForAction" :contractActions="contractActions" />

      <v-col cols="auto" v-if="!!documentId">
        <ContractAction
          :contract-status="status"
          :seminar-start-at="seminarStartAt"
          :contract-id="contract.id"
          :document-id="documentId"
          @reload="listContractActions"
          :contract-actions="contractActions"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import ContractStatus from "./partials/ContractStatus.vue";
import ContractAction from "@views/Restricted/SeminarSpeaker/MyContracts/Show/partials/SpeakerContract/partials/ContractAction.vue";
import ContractActionIndicator from "@components/ContractActionIndicator/ContractActionIndicator.vue";
import speakerContractStatusGroups from "@/constants/speakerContractStatusGroups";

export default {
  name: "SpeakerContract",

  components: {
    ContractActionIndicator,
    ContractAction,
    ContractStatus,
  },

  data() {
    return {
      contractActions: [],
      isLoading: true,
    };
  },

  props: {
    contract: {
      type: Object,
      required: true,
    },

    seminarStartAt: {
      type: String,
      required: true,
    },

    documentId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    status() {
      return this.contract.speakerContractStatus;
    },

    role() {
      return this.contract.speakerRole;
    },

    contractIsNeedForAction() {
      return (
        this.contract.speakerContractStatus.group ===
        speakerContractStatusGroups.NEED_FOR_ACTION.key
      );
    },
  },

  methods: {
    async listContractActions() {
      if (!this.contract) return;

      try {
        const { data } = await this.$axios.seminarManagement.listContractActions(this.contract.id);
        this.contractActions = data.data;
      } finally {
        this.isLoading = false;
      }
    },
  },

  async created() {
    await this.listContractActions();
  },
};
</script>
