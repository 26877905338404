<template>
  <div v-if="showContracts" class="mb-8">
    <div class="mb-4">
      <h4>{{ $t("SEMINAR_SPEAKER.CONTRACT_DETAILS.TITLE") }}</h4>
    </div>

    <SpeakerContract
      :contract="contract"
      v-for="contract of speakerContracts"
      :key="contract.id"
      :seminar-start-at="seminar.startAt"
      :document-id="documentId"
    />
  </div>
</template>

<script>
import SpeakerContract from "../SpeakerContract/index.vue";
import { isEmpty } from "lodash";

export default {
  name: "SpeakerContracts",

  data() {
    return {
      speakerContracts: [],
    };
  },

  components: {
    SpeakerContract,
  },

  props: {
    seminar: {
      type: Object,
      required: true,
    },

    documentId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    showContracts() {
      return !isEmpty(this.speakerContracts);
    },
  },

  methods: {
    async listContracts() {
      if (!this.seminar.id) return;

      const { data } = await this.$axios.seminarManagement.listContracts(this.seminar.id);
      this.speakerContracts = data.data;
    },
  },

  async created() {
    await this.listContracts();
  },
};
</script>
