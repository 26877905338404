<template>
  <BaseLabel :color="group.colors.background">
    <BaseDot :color="group.colors.text" />
    <span class="text-caption font-weight-bold" :class="`${group.colors.text}--text`">
      {{ group.name }}
    </span>
  </BaseLabel>
</template>

<script>
import speakerContractStatusGroups from "@/constants/speakerContractStatusGroups";

export default {
  name: "ContractStatus",

  props: {
    contractStatus: {
      type: Object,
      required: true,
    },
  },

  computed: {
    group() {
      return Object.values(speakerContractStatusGroups).find((group) => {
        return this.contractStatus.group === group.key;
      });
    },
  },
};
</script>
